// Core
import { Injectable } from '@angular/core';

// Firebase
import { Auth, signInWithEmailAndPassword, signInAnonymously, authState, signOut, deleteUser, getAuth, updateProfile, updateEmail, updatePassword, sendPasswordResetEmail, verifyPasswordResetCode, confirmPasswordReset, EmailAuthProvider, linkWithCredential } from '@angular/fire/auth';
import { Firestore, doc, docData, updateDoc, serverTimestamp } from '@angular/fire/firestore';

// Service
import { UserService } from '../user/user.service';

// Interface
import { IUser } from '../../interface/user/user';

// Rxjs
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private auth: Auth,
    private firestore: Firestore,
    private userService: UserService,
  ) {
    // 로그인 유저 정보 조회
    this.authUser$ = authState(this.auth).pipe(
      switchMap((user) => {
        if (user) {
          return this.authUser ? of(this.authUser) : (docData(doc(this.firestore, `user/${user.uid}`)) as Observable<IUser>);
        } else {
          return of(null);
        }
      })
    );

    // 로그인 유저 데이터 설정
    this.authUser$.subscribe(user => {
      // 로그아웃시 새로고침
      if (this.authUser && this.authUser.temporary === 'sign-out' && !user) {
        window.location.reload();
      }
      
      // 회원 정보
      this.authUser = user || null;
    });
  }

  // 로그인 유저
  public authUser: IUser|null = null;
  public authUser$: Observable<IUser|null>;

  // 로그인 유저 업데이트
  public updateAuthUser(user: IUser): void {
    this.authUser = user;
  }

  // 로그인
  public async signIn(email: string, password: string): Promise<void> {
    // 로그인 요청
    await signInWithEmailAndPassword(this.auth, email, password);
    
    // 현재 로그인 유저
    const currentUser = this.auth.currentUser;

    // 업데이트 쿼리
    const userDoc = doc(this.firestore, `user/${currentUser && currentUser.uid}`);

    // 문서 업데이트
    updateDoc(userDoc, {
      loginedAt: serverTimestamp(),
    });
  };

  // 회원가입
  public async signUp(nickname: string): Promise<void> {
    // 회원가입 요청
    const auth = await signInAnonymously(this.auth);

    // 사용자 생성 요청
    await this.userService.createUser(auth.user.uid, { nickname: nickname });
  }

  // 로그아웃
  public async signOut(): Promise<void> {
    // 사용자 없을시 리턴
    if (!this.authUser) {
      return;
    }

    // 로그아웃 플래그 설정
    this.authUser.temporary = 'sign-out';
    
    // 로그아웃
    await signOut(this.auth);
  };

  // 사용자 삭제
  public async signDelete(id: string, email: string, password: string): Promise<void> {
    // 사용자 없을시 리턴
    if (!this.authUser) {
      return;
    }

    // 재 로그인
    await signInWithEmailAndPassword(this.auth, email, password);

    // 현재 로그인 사용자
    const user = getAuth().currentUser;

    // 로그인 사용자 없을시 리턴
    if (!user) {
      return;
    }

    // 사용자 삭제 플래그 설정
    this.authUser.temporary = 'sign-delete';

    // 사용자 데이터 삭제
    await this.userService.deleteUser(id);

    // 사용자 삭제
    await deleteUser(user);
  };

  // 계정 업데이트
  public async updateCredential(email: string, password: string): Promise<void> {
    // 사용자 없을시 리턴
    if (!this.authUser) {
      return;
    }

    // 이메일/비밀번호 설정
    const credential = EmailAuthProvider.credential(email, password)

    // 현재 로그인 사용자
    const user = getAuth().currentUser;

    // 현재 로그인한 사용자 없을시 리턴
    if (!user) {
      return;
    }

    // 이메일/비밀번호 연결
    await linkWithCredential(user, credential);

    // 이메일 업데이트
    await this.userService.updateUser(user.uid, {
      email: email,
    });
  }

  // 이메일 변경
  public async updateEmail(email: string, password: string): Promise<void> {
    // 사용자 없을시 리턴
    if (!this.authUser) {
      return;
    }

    // 재 로그인
    await signInWithEmailAndPassword(this.auth, this.authUser.email, password);

    // 현재 로그인 사용자
    const user = getAuth().currentUser;

    // 로그인 사용자 없을시 리턴
    if (!user) {
      return;
    }

    // 이메일 변경
    await updateEmail(user, email);

    // 이메일 업데이트
    await this.userService.updateUser(user.uid, {
      email: email,
    });
  }

  // 비밀번호 변경
  public async updatePassword(current: string, password: string): Promise<void> {
    // 사용자 없을시 리턴
    if (!this.authUser) {
      return;
    }

    // 재 로그인
    await signInWithEmailAndPassword(this.auth, this.authUser.email, current);

    // 현재 로그인 사용자
    const user = getAuth().currentUser;

    // 로그인 사용자 없을시 리턴
    if (!user) {
      return;
    }

    // 비밀번호 변경
    await updatePassword(user, password);
  }

  // 비밀번호 재설정 메일 발송
  public async findPassword(email: string): Promise<void> {
    await sendPasswordResetEmail(this.auth, email);
  }

  // 비밀번호 재설정
  public async resetPassword(code: string, password: string): Promise<void> {
    // 인증 코드 확인
    await verifyPasswordResetCode(this.auth, code);

    // 비밀번호 재설정
    await confirmPasswordReset(this.auth, code, password)
  }

}
