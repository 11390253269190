// Core
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Guard
import { AuthGuard } from './shared/guard/auth/auth.guard';

// Routes
const routes: Routes = [
  {
    path: 'sign',
    loadChildren: () => import('./page/sign/sign.module').then(m => m.SignModule),
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadChildren: () => import('./page/home/home.module').then(m => m.HomeModule),
    canActivate: [
      AuthGuard,
    ],
    data: {
      role: [
        'admin',
      ],
      access: '',
    },
  },
  {
    path: 'letter',
    loadChildren: () => import('./page/letter/letter.module').then(m => m.LetterModule),
    canActivate: [
      AuthGuard,
    ],
    data: {
      role: [
        'admin',
      ],
      access: '',
    },
  },
  {
    path: 'contact',
    loadChildren: () => import('./page/contact/contact.module').then(m => m.ContactModule),
    canActivate: [
      AuthGuard,
    ],
    data: {
      role: [
        'admin',
      ],
      access: '',
    },
  },
  {
    path: 'notice',
    loadChildren: () => import('./page/notice/notice.module').then(m => m.NoticeModule),
    canActivate: [
      AuthGuard,
    ],
    data: {
      role: [
        'admin',
      ],
      access: '',
    },
  },
  {
    path: 'notification',
    loadChildren: () => import('./page/notification/notification.module').then(m => m.NotificationModule),
    canActivate: [
      AuthGuard,
    ],
    data: {
      role: [
        'admin',
      ],
      access: '',
    },
  },
  {
    path: 'setting',
    loadChildren: () => import('./page/setting/setting.module').then(m => m.SettingModule),
    canActivate: [
      AuthGuard,
    ],
    data: {
      role: [
        'admin',
      ],
      access: '',
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [
    RouterModule,
  ],
})
export class AppRoutingModule { }
