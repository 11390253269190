// Core
import { NgModule } from '@angular/core';
import { BrowserModule, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { COMPOSITION_BUFFER_MODE } from '@angular/forms';

// App
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Animation
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Modal
import { NgxSmartModalModule } from 'ngx-smart-modal';

// Toast
import { ToastrModule } from 'ngx-toastr';

// Hammer
import { HammerConfig } from './shared/config/hammer/hammer.config';

// Firebase
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { provideAuth,getAuth } from '@angular/fire/auth';
import { provideFirestore,getFirestore } from '@angular/fire/firestore';
import { provideFunctions,getFunctions } from '@angular/fire/functions';
import { provideMessaging,getMessaging } from '@angular/fire/messaging';
import { provideStorage,getStorage } from '@angular/fire/storage';

// Environment
import { environment } from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    HammerModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxSmartModalModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 3000,
      easeTime: 150,
      positionClass: 'toast-top-center'
    }),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideFunctions(() => getFunctions()),
    provideMessaging(() => getMessaging()),
    provideStorage(() => getStorage())
  ],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig,
    },
    {
			provide: COMPOSITION_BUFFER_MODE,
			useValue: false,
		},
  ],
  bootstrap: [
    AppComponent,
  ],
})
export class AppModule { }
