// Core
import { Injectable } from '@angular/core';

// Firebase
import { Firestore, doc, setDoc, updateDoc, deleteDoc, serverTimestamp } from '@angular/fire/firestore';

// Interface
import { IUser } from '../../interface/user/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private firestore: Firestore,
  ) { }

  // 회원 생성
  public async createUser(id: string, data: object): Promise<void> {
    // 문서 경로
    const ref = doc(this.firestore, `user/${id}`);

    // 기본 정보
    const basic: IUser = {
      id: id,
      name: '',
      nickname: '',
      email: '',
      emailVerified: false,
      emailReception: false,
      phone: '',
      phoneReception: false,
      letterReception: true,
      pushReception: true,
      photo: '',
      birthday: '',
      gender: '',
      access: [],
      role: 'user',
      terms: {
        use: true,
        privacy: true,
      },
      active: true,
      block: false,
    };

    // 생성 요청
    await setDoc(ref, {
      ...basic,
      ...data,
      loginedAt: serverTimestamp(),
      passwordChangedAt: serverTimestamp(),
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });
  }

  // 회원 업데이트
  public async updateUser(id: string, data: object): Promise<void> {
    // 문서 경로
    const ref = doc(this.firestore, `user/${id}`);

    // 업데이트
    await updateDoc(ref, {
      ...data,
      updatedAt: serverTimestamp(),
    });
  }

  // 회원 삭제
  public async deleteUser(id: string): Promise<void> {
    // 문서 경로
    const ref = doc(this.firestore, `user/${id}`);

    // 삭제
    await deleteDoc(ref);
  }
  
}
